import myAxios from '@/services/myAxios'

export default {
  state: {
    teams: [],
  },
  getters: {
    teamsCrud: (state) => state.teams,
  },
  mutations: {
    //sets all team
    'team/set': (state, payload) => {
      state.teams = payload
    }, //sets all team
    'team/push': (state, payload) => {
      state.teams.push(payload)
    },
    //store one team
    'team/destroy': (state, id) =>
      (state.teams = state.teams.filter((item) => {
        return item._id !== id
      })),
    //updates one team
    'team/update': (state, payload) => {
      state.teams = state.teams.map((item) => {
        if (item._id === payload._id) {
          return { ...item, ...payload }
        }
        return item
      })
    },
  },
  actions: {
    //fetch all team
    'team/index': async (context) => {
      const { data } = await myAxios.get('/team')
      context.commit('team/set', data)
    },
    //stores one team
    'team/store': async (context, payload) => {
      let { data } = await myAxios.post('/team', { ...payload })
      context.commit('team/push', data.id)
    },
    //destroys one team
    'team/destroy': async (context, id) => {
      await myAxios.delete('/team/' + id)
      context.commit('team/destroy', id)
    },
    //updates one team by its id
    'team/update': async (context, payload) => {
      await myAxios.put('/team/' + payload._id, payload)
      context.commit('team/update', payload)
    },
  },
}
